import { useState, useCallback } from 'react';
import transactionRepository from '../repositories/transactionRepository';
import { Transaction } from '../types/model/transaction';

const useTransac = () => {
    const [transac, setTransaction] = useState<Transaction | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);



    // Fetch transactions
    const fetchData = useCallback(async (codeTransaction: string) => {
        setLoading(true);
        setError(null);
        try {
            const result = await transactionRepository.getTransaction(codeTransaction);
            setTransaction(result);
        } catch (error) {
            setError("Failed to fetch transaction");
        } finally {
            setLoading(false);
        }
    }, []);





    return {
        transac,
        loading,
        error,
        fetchData
    };
};

export default useTransac;
