import axios from "axios";
import urls from '../../constant/urls';

const coreApi = axios.create({
  baseURL: urls.coreUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Ajouter un intercepteur de requête
coreApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(`error ${error}`);
  }
);

// Ajouter un intercepteur de réponse
coreApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.detail === "Invalid token"
    ) {
      // Stocker la route actuelle dans localStorage pour redirection après login
      const currentPath = window.location.pathname;
      localStorage.setItem("next-route", currentPath);

      // Rediriger vers la page de déconnexion
      window.location.href = "/logout"; // Remplacez par la route correcte
    }
    return Promise.reject(error);
  }
);

export default coreApi;
