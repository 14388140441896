import { Adresse } from './adresseModel';
import { Panier } from './cart';

const Status = Object.freeze({
    PENDING: 'PEN',
    CANCELLED: 'CAN',
    EXPIRED: 'EXP',
    VALIDATED: 'VAL',
    ACTIVATED: 'ACT',
    InProgress: 'INP'
})


export type StatusType = typeof Status[keyof typeof Status]; // Type pour les valeurs de statut

export interface Order {
    idCommande: number;
    panier: Panier;
    details: Adresse;
    codeCommande: string;
    montant: number;
    statut: string;
    dateCommande: string;
    dateUpdate: string
}

export interface OrderModel {
    idCommande: number;
    panier: Panier;
    details: Adresse;
    codeCommande: string;
    montant: number;
    statut: StatusType;
    dateCommande: string;
    dateUpdate: string
}

export { Status, };