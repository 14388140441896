import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
// import { Images } from "../../constant";
// import { decrypt } from "../../constant/crypto";
// import { useFormContext } from "../../context/formContext";
import { InternalError } from "../InternalError";
import { AppDispatch, RootState } from "../../store";
import { fetchCurrentOrder } from "../../store/orderSlice";
import LoadingSpinner3 from "../../components/loading/loading_spinner3";
import useTransaction from "../../hooks/useTransaction";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const PaymentPage = () => {
    const { user } = useSelector((state: RootState) => state.auth);
    // const { code } = useParams<{ code: string }>();
    // const { formData } = useFormContext();



    const { currentOrder, loadingGet, errorGet } = useSelector((state: RootState) => state.order);
    const { loadingCreation, errorCreation, createTransaction } = useTransaction()


    // const codeDecrypt = useMemo(
    //     () => decrypt(code ?? "", user?.codeUtilisateur ?? "cloud"),
    //     [code, user]
    // );
    const dispatch = useDispatch<AppDispatch>();


    useEffect(() => {
        if (user) {
            dispatch(fetchCurrentOrder(user.codeUtilisateur));
        }  // Fetch cart using Redux action

    }, [dispatch, user]);


    // const handleChange = useCallback(
    //     (e: React.ChangeEvent<HTMLInputElement>) => {
    //         const { value } = e.target;
    //         setFormData((prevData) => ({
    //             ...prevData,
    //             methodOfPayment: value,
    //         }));
    //     },
    //     [setFormData]
    // );

    const handleCreatePaiment = useCallback(() => {
        if (user && currentOrder) {
            createTransaction(currentOrder.codeCommande);
        }
    }, [user, currentOrder, createTransaction]);

    const handleSubmit = () => {
        handleCreatePaiment();
    };

    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleToggle = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };


    const showToast = useCallback(
        () => {
            if (!loadingCreation) {
                if (errorCreation) {
                    toast.error(errorCreation || 'Erreur inattendue');
                }

            }
        },
        [loadingCreation, errorCreation],
    )

    useEffect(() => {
        showToast()
    }, [errorCreation, loadingCreation, showToast])



    if (loadingGet || currentOrder == null) return <div className='w-full h-screen flex items-center'>
        <div className='mx-auto container px-4 xl:px-16 2xl:px-0'>
            <LoadingSpinner3 />
        </div>
    </div>;
    if (errorGet) return <section className='w-full h-screen flex items-center'>

        <div className='mx-auto container px-4 xl:px-16 2xl:px-0'>
            <InternalError />
        </div>
    </section>;
    const { codePostal, adresse, ville, pays } =
        currentOrder!.details;

    // const { methodOfPayment } = formData;



    return (
        <div
            className={clsx(
                "flex flex-col items-center relative items-center lg:h-full"
            )}
        >
            <div className=" w-full container mx-auto px-4 xl:px-16 2xl:px-0">
                <div className="w-full flex flex-col md:flex-row gap-8 lg:gap-16 h-full py-8">
                    <div className="w-full md:w-1/2 lg:w-7/12  2xl:w-2/3 h-full flex flex-col gap-6">
                        <h3 className="text-2xl lg:text-4xl font-semibold">Confirmer la commande</h3>

                        <div id="address_on_payment" className="mt-4 w-full 2xl:w-3/4   ">
                            <div className="transition-all duration-200 ease-in-out">
                                <div id="shipping_and_billing" className="block box-border flex-1  basis-auto p-4  2xl:p-6">
                                    <a className="cursor-pointer no-underline float-right" href="/shop/checkout">
                                        <FontAwesomeIcon icon={faPencil} className="fa fa-pencil mr-1" />Modifier</a>
                                    <div className="text-lg ">
                                        <b>Facturation: </b>
                                        <span className="text-base" data-oe-type="contact" data-oe-expression={currentOrder.codeCommande}>
                                            <address className="o_portal_address mb-0">
                                                <div className="gap-2" itemType="http://schema.org/PostalAddress">

                                                    <div className="flex items-baseline gap-1">
                                                        <span className="w-100 leading-5 whitespace-nowrap" itemProp="streetAddress">{adresse}, {codePostal} {ville}, {pays}</span>
                                                    </div>
                                                </div>
                                                <div>

                                                </div>
                                            </address></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="w-full lg:w-3/4 flex flex-col gap-4 mt-8">
                            <h4 className="uppercase font-bold">
                                Choisissez un mode de paiement
                            </h4>
                            <div className="w-full border border-gray-400 rounded-lg ">
                                <div
                                    className={clsx("p-4 flex w-full flex-col gap-4", {
                                        "border-2 rounded-t-md border-sky-600":
                                            methodOfPayment === "creditCard",
                                    })}
                                >
                                    <div className="w-full flex justify-between gap-4">
                                        <div className="inline-flex items-center  whitespace-nowrap">
                                            <input
                                                type="radio"
                                                name="methodOfPayment"
                                                onChange={handleChange}
                                                id="creditCard"
                                                value="creditCard"
                                                checked={methodOfPayment === "creditCard"}
                                            />
                                            <label htmlFor="creditCard" className="ml-2 font-medium">
                                                Carte de Credit
                                            </label>
                                        </div>
                                        <div>
                                            <div className=" grid grid-cols-2 lg:grid-cols-4 gap-1">
                                                <span
                                                    className="relative block rounded overflow-hidden z-1 shadow-sm"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-delay="0"
                                                    title=""
                                                    data-bs-original-title="American Express"
                                                    aria-label="American Express"
                                                >
                                                    <img
                                                        src={Images.americanExpress}
                                                        className="img img-fluid"
                                                        alt="American Express"
                                                        loading="lazy"
                                                    />
                                                </span>
                                                <span
                                                    className="relative block rounded overflow-hidden z-1 shadow-sm"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-delay="0"
                                                    title=""
                                                    data-bs-original-title="Maestro"
                                                    aria-label="Maestro"
                                                >
                                                    <img
                                                        src={Images.maestro}
                                                        className="img img-fluid"
                                                        alt="Maestro"
                                                        loading="lazy"
                                                    />
                                                </span>
                                                <span
                                                    className="relative block rounded overflow-hidden z-1 shadow-sm"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-delay="0"
                                                    title=""
                                                    data-bs-original-title="MasterCard"
                                                    aria-label="MasterCard"
                                                >
                                                    <img
                                                        src={Images.masterCard}
                                                        className="img img-fluid"
                                                        alt="MasterCard"
                                                        loading="lazy"
                                                    />
                                                </span>
                                                <span
                                                    className="relative block rounded overflow-hidden z-1 shadow-sm"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-delay="0"
                                                    title=""
                                                    data-bs-original-title="VISA"
                                                    aria-label="VISA"
                                                >
                                                    <img
                                                        src={Images.visa}
                                                        className="img img-fluid"
                                                        alt="VISA"
                                                        loading="lazy"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={clsx({
                                            hidden: methodOfPayment !== "creditCard",
                                        })}
                                    ></div>
                                </div>
                                <div
                                    className={clsx("p-4 flex w-full flex-col gap-4 border-t-2", {
                                        "border-2 rounded-b-md border-sky-600":
                                            methodOfPayment === "mobileMoney",
                                    })}
                                >
                                    <div className="w-full flex justify-between ">
                                        <div className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                onChange={handleChange}
                                                name="methodOfPayment"
                                                id="mobileMoney"
                                                value="mobileMoney"
                                                checked={methodOfPayment === "mobileMoney"}
                                            />
                                            <label htmlFor="mobileMoney" className="ml-2 font-medium">
                                                Mobile Money
                                            </label>
                                        </div>

                                        <div className="grid grid-cols-2  gap-1">
                                            <span
                                                className="relative block rounded overflow-hidden z-1 shadow-sm"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                data-bs-delay="0"
                                                title=""
                                                data-bs-original-title="Orange Money"
                                                aria-label="Orange Money"
                                            >
                                                <img
                                                    src={Images.orange}
                                                    className="w-12"
                                                    alt="Orange Money"
                                                    loading="lazy"
                                                />
                                            </span>
                                            <span
                                                className="relative block rounded overflow-hidden z-1 shadow-sm"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                data-bs-delay="0"
                                                title=""
                                                data-bs-original-title="MTN MONEY"
                                                aria-label="MTN MONEY"
                                            >
                                                <img
                                                    src={Images.mtn}
                                                    className="w-12"
                                                    alt="MTN MONEY"
                                                    loading="lazy"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className={clsx({
                                            hidden: methodOfPayment !== "mobileMoney",
                                        })}
                                    >
                                        <p className="text-justify text-sm  pl-6">
                                            {" "}
                                            Veuillez noter que <i>des frais de traitement</i>{" "}
                                            supplémentaires équivalant à environ <b>5 %</b> du montant
                                            total seront facturés afin de couvrir les frais de
                                            transaction par l'operateur.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div>

                        <div className=" mt-6 max-w-sm w-full  space-y-6 lg:mt-0 ">
                            <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm  sm:p-6">

                                <button
                                    className="accordion-toggle group inline-flex items-center justify-between leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600"
                                    onClick={() => handleToggle(1)}
                                    aria-controls={`collapse-1`}
                                >
                                    <div className="w-full text-start">
                                        <h3 className="text-xl font-semibold text-gray-900 ">
                                            Résumé de la commande
                                        </h3>
                                        <span>{currentOrder.panier.items.length} article(s)</span>
                                    </div>
                                    <svg
                                        className={`text-gray-900 transition duration-500 group-hover:text-indigo-600 ${activeIndex === 1 ? 'rotate-180' : ''
                                            }`}
                                        width={22}
                                        height={22}
                                        viewBox="0 0 22 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                                            stroke="currentColor"
                                            strokeWidth="1.6"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </button>
                                <div
                                    id={`collapse-1`}
                                    className={clsx('accordion-content w-full px-0 overflow-hidden pr-4 transform overflow-hidden transition-all duration-500 ease-in-out',
                                        {
                                            'active max-h-[500px]': activeIndex === 1,
                                            'max-h-0': activeIndex !== 1,
                                        })}

                                >
                                    <div className="grid grid-cols-1 divide-y">
                                        {currentOrder.panier.items.map(item =>
                                        (
                                            <div className='px-8 text-sm '>
                                                <ul className='list-outside list-disc space-y-2'>
                                                    <li>
                                                        <span>Instance <span className="font-bold">{item.produitService.nom}</span> </span>
                                                        <div className='w-full flex flex-col '>
                                                            <ul className='list-outside px-4 leading-6'>
                                                                <li>Plan : <span className='font-bold capitalize'>{item.planSouscription.nom}</span>
                                                                </li>
                                                                <li>Prix  :  <span>{Math.ceil(item.planSouscription.prix)} € /mois</span></li>
                                                                <li>Autres services ...</li>

                                                            </ul>
                                                        </div>
                                                    </li>
                                                    {item.urlInstance && <li>
                                                        <h3>Url de l'instance associée : </h3>
                                                        <span className="font-semibold text-gray-800 text-xs">{item.urlInstance}</span>
                                                    </li>}
                                                    {item.supportMaintenance && (
                                                        <li>
                                                            <div className='flex flex-col w-full leading-8'>
                                                                <span className='font-medium'><span className='font-normal'>Support de maintenance ({Math.floor(item.supportMaintenance.prix)} €)</span></span>
                                                                <span className='px-4 '> {item.supportMaintenance.nombreTickets} Tickers/mois </span>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                                <div className="space-y-4">
                                    <div className="space-y-8">
                                        <dl className="flex items-center justify-between gap-4">
                                            <dt className="text-base font-normal text-gray-500 ">
                                                Prix ​​d'origine
                                            </dt>
                                            <dd className="text-base font-medium text-gray-900 ">
                                                {currentOrder?.montant} €
                                            </dd>
                                        </dl>
                                    </div>
                                    <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
                                        <dt className="text-base font-bold text-gray-900 ">
                                            Total
                                        </dt>
                                        <dd className="text-base font-bold text-gray-900 ">
                                            {currentOrder?.montant} €
                                        </dd>
                                    </dl>
                                </div>
                                <div className="block w-full flex flex-col gap-6">
                                    <h4 className="font-bold text-xl">Conditions de ventes</h4>
                                    <div>
                                        <span className="uppercase">Ventes Finales</span> - Veuillez
                                        noter que toutes les ventes d'abonnements Cloudapps
                                        Enterprise et des services connexes sont définitives et non
                                        remboursables.
                                    </div>
                                </div>
                                <button
                                    disabled={loadingCreation}
                                    onClick={handleSubmit}

                                    className={clsx(
                                        "flex w-full  items-center justify-center rounded-lg bg-primary-500 transition-all duration-300 ease-out px-5 py-2.5  font-bold text-white ",
                                        {
                                            " opacity-100 hover:bg-blue-500 hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 ":
                                                true,
                                        }
                                    )}
                                >
                                    <div className={clsx("flex justify-center items-center", { "hidden": !loadingCreation })}>
                                        <svg
                                            width={20}
                                            height={20}
                                            fill="currentColor"
                                            className="mr-2 animate-spin"
                                            viewBox="0 0 1792 1792"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                                        </svg>
                                        <span>loading</span>
                                    </div>
                                    <span className={clsx({ "hidden": loadingCreation })}>Confirmer</span>
                                </button>
                                <div className="flex items-center justify-center gap-2">
                                    <span className="text-sm font-normal text-gray-500 ">
                                        {" "}
                                        ou{" "}
                                    </span>
                                    <a
                                        href="/cart"
                                        title=""
                                        className="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline "
                                    >
                                        Retour au panier
                                        <svg
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M19 12H5m14 0-4 4m4-4-4-4"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};




