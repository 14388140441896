import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { logout } from "../store/authSlice";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    useEffect(() => {
        // Déconnexion de l'utilisateur
        dispatch(logout());

        // Redirection après déconnexion
        navigate("/signin");
    }, [dispatch, navigate]);

    return null; // Pas besoin d'affichage
};
