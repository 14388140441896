import { ProductModal } from "../types/constants/products/modal_product";
import {
    ImplementationService,
    PlanSouscription,
    SupportMaintenance,
} from "../types/model/cart";
import { MaintenanceProp } from "../types/model/maintenance_prop";

export const handleGetImplementation = ({
    type,
    implementationServices,
}: {
    type: string;
    implementationServices: ImplementationService[];
}): ProductModal[] => {
    const filteredData = implementationServices.filter(
        (e) => e.typeProduitService.toLowerCase() === type.toLowerCase()
    );

    return filteredData.map((e, index) => {

        return {
            index,
            name: e.nomService,
            products: e.produitsAssocies ? (e.produitsAssocies).split(',') : [],
        }
    });
};

export const handleGetMaintenances = ({
    maintenances,
}: {
    maintenances: SupportMaintenance[];
}): MaintenanceProp[] => {
    return maintenances.map((e) => ({
        type: e.codeSupportMaintenance,
        value: e.nombreTickets + " tickets maximum par mois",
        price: e.prix//Math.floor(Number(e.prix)),
    }));
};

export const handleGetMaintenance = ({
    maintenances,
    code,
}: {
    maintenances: SupportMaintenance[];
    code: string;
}): number => {
    return maintenances.filter((e) => e.codeSupportMaintenance === code)[0]
        .idSupportMaintenance;
};

export const getPlanIdByName = (
    plans: PlanSouscription[],
    name: string
): number | null => {
    const plan = plans.find((plan) => plan.nom.toLowerCase() === name.toLowerCase());
    return plan ? plan.idPlanSouscription : null; // Retourne l'ID ou null si non trouvé
};

export const getIdsByNames = (
    data1: ImplementationService[],
    data2: string[]
): number[] => {
    return data1
        .filter((service) => data2.includes(service.nomService)) // Filtrer les services dont le nom est dans data2
        .map((service) => service.idImplementationService); // Mapper pour obtenir les IDs
};


export const generateRandomString = (prefix: string, suffixLength: number = 8): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const randomSuffix = Array.from({ length: suffixLength }, () =>
        characters.charAt(Math.floor(Math.random() * characters.length))
    ).join('');

    return `${prefix}-${randomSuffix}`;
};

export function convertToValidUrl(url: string): string {
    // Ajoute "https://" si l'URL ne commence pas par "http://" ou "https://"
    return url.startsWith('http://') || url.startsWith('https://')
        ? url
        : `https://${url}`;
}
