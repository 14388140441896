import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Products from './pages/Products';
// import Blog from './pages/Blogs';
import SignIn from './pages/SignIn';
import { ProductDetail } from './pages/product_detail';
import Cart from './pages/cart';
import Register from './pages/register';
import GoogleAuthSuccess from './pages/auth-succes';
import { NotFound } from './pages/NotFound';
import PrivateRoute from './components/private_route';
import { AddressPage } from './pages/subscription/address_page';
import { CheckoutPage } from './pages/subscription/checkout';
import { SubscribePage } from './pages/subscription/subscribe_page';
import { AboutUs } from './pages/AboutUs';
import MeLayout from './components/me/layout';
import { ProfilPage } from './pages/me/Profil';
import { Orders } from './pages/me/Orders';
import ThankYouPage from './pages/thankyou';
import { Transactions } from './pages/me/Transaction';
import { Subscriptions } from './pages/me/Subscriptions';
import { PaymentPage } from './pages/subscription/payment';
import ShopLayout from './components/shop/layout';
import ContactThankYouPage from './pages/contact_us_thank';
import { Logout } from './pages/Logout';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products/:serviceSlug" element={<ProductDetail />} />
      {/* <Route path="/blog" element={<Blog />} /> */}
      <Route path="/signin" element={<SignIn />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/register" element={<Register />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/about" element={<AboutUs />} />

      {/* Routes protégées */}
      <Route element={<PrivateRoute />}>

        <Route path="/shop" element={<ShopLayout />}>
          <Route path="" element={<Navigate to="/shop/checkout" replace />} />
          <Route path="address/:code?" element={<AddressPage />} />
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="payment" element={<PaymentPage />} />

        </Route>

        <Route path="/me" element={<MeLayout />}>
          <Route path="" element={<Navigate to="/me/profil" replace />} />
          <Route path="profil" element={<ProfilPage />} />
          <Route path="subscriptions" element={<Subscriptions />} />
          <Route path="orders" element={<Orders />} />
          <Route path="paiements" element={<Transactions />} />
        </Route>
      </Route>

      <Route path="/thank-you" element={<ThankYouPage />} />
      <Route path="/contactus-thank-you" element={<ContactThankYouPage />} />

      <Route path="/subscribe/:code/:codeItem?" element={<SubscribePage />} />

      <Route path='/auth-success' element={<GoogleAuthSuccess />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
