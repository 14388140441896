import orderService from "../services/orderService";
import { Order, OrderModel, Status, StatusType } from "../types/model/order";

function mapStatus(status: string): StatusType {
  switch (status) {
    case "PEN":
      return Status.PENDING;
    case "CAN":
      return Status.CANCELLED;
    case "EXP":
      return Status.EXPIRED;
    case "VAL":
      return Status.VALIDATED;
    case "INP":
      return Status.InProgress;
    default:
      throw new Error(`Statut inconnu: ${status}`); // Gérer les statuts inconnus
  }
}

// Fonction pour convertir un Order en OrderModel
function convertOrderToOrderModel(order: Order): OrderModel {
  return {
    ...order,
    statut: mapStatus(order.statut), // Mapper le statut
  };
}

const orderRepository = {
  createOrder: async (codePanier: string, codeAdresse: string): Promise<Order> => {
    try {
      return await orderService.createOrder(codePanier, codeAdresse);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  updateOrder: async (codeCommande: string, codeAdresse: string): Promise<Order> => {
    try {
      return await orderService.updateOrder(codeCommande, codeAdresse);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  cancelOrder: async (
    codeUtilisateur: string,
    codeOrder: string
  ): Promise<void> => {
    try {
      await orderService.cancelOrder(codeUtilisateur, codeOrder);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  getOrder: async (codeUtilisateur: string): Promise<Order> => {
    try {
      const response = await orderService.getOrder(codeUtilisateur);
      return response;
    } catch (error) {
      console.error("get oder:", error);
      throw error;
    }
  },

  getOrders: async (codeUtilisateur: string): Promise<OrderModel[]> => {
    try {
      const orders = await orderService.getOrders(codeUtilisateur);
      return orders.map(convertOrderToOrderModel);
    } catch (error) {
      console.error("get oders:", error);
      throw error;
    }
  },
};

export default orderRepository;
