import transactionService from "../services/transactionService";
import { Transaction } from "../types/model/transaction";




const transactionRepository = {
  createTransaction: async (
    codeCommande: string,
  ): Promise<void> => {
    try {
      await transactionService.createTransaction(codeCommande);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  cancelTransaction: async (
    codeTransaction: string,
  ): Promise<void> => {
    try {
      await transactionService.cancelTransaction(codeTransaction);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },


  getTransactions: async (): Promise<Transaction[]> => {
    try {
      const transactions = await transactionService.getTransactions();
      return transactions;
    } catch (error) {
      console.error("get transactions:", error);
      throw error;
    }
  },

  getTransaction: async (codeTransaction: string): Promise<Transaction> => {
    try {
      const transactions = await transactionService.getTransaction(codeTransaction);
      return transactions;
    } catch (error) {
      console.error("get transactions:", error);
      throw error;
    }
  },
};

export default transactionRepository;
