import React, { useEffect, useId, useCallback, useMemo } from "react";
import { ImplementationService, PanierItem } from "../../types/model/cart";

const ModalCartItem = ({ item, show, onShow }: { item: PanierItem, show: boolean, onShow: (e: boolean) => void }) => {
    const [showModal, setShowModal] = React.useState(show);

    useEffect(() => {
        setShowModal(show);
    }, [show]);

    useEffect(() => {
        onShow(showModal);
    }, [showModal, onShow]);

    const id = useId();

    const handleOpen = useCallback(() => {
        setShowModal(true);
    }, []);

    const handleClose = useCallback(() => {
        setShowModal(false);
    }, []);

    const implementationServicesList = useMemo(() => (
        item.implementationServices?.map((product: ImplementationService, index: number) => (
            <li key={`${id}-${index}`}>{product.nomService}</li>
        ))
    ), [item.implementationServices, id]);

    return (
        <>
            <button
                key={id}
                onClick={handleOpen}
                type="button"
                className="flex hover:underline group flex gap-1 items-center text-sm font-medium text-primary-500"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width={17} className="stroke-2" height={17}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
                <span className='text-md font-semibold'>Aperçu</span>
            </button>
            {showModal && (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-96 md:w-auto my-6 mx-auto md:max-w-5xl shadow">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-xl lg:text-3xl font-semibold">Détail de la souscription</h3>
                                    <button onClick={handleClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center">
                                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <div className="space-y-4 text-lg leading-10 text-gray-800">
                                        <div className='w-full flex flex-wrap lg:justify-between gap-8 lg:gap-0'>
                                            <div className='w-full md:w-1/2 flex flex-col gap-8'>
                                                <div>
                                                    <span className="text-md lg:text-xl leading-10 font-semibold underline underline-offset-4">Abonnement <span className="font-light">({item.periodeSouscription.nom})</span> :</span>
                                                    <div className='px-8 pt-4 text-sm lg:text-base'>
                                                        <ul className='list-outside list-disc space-y-4'>
                                                            <li>
                                                                <span>Instance <span className="font-bold">{item.produitService.nom}</span> </span>
                                                                <div className='w-full flex flex-col '>
                                                                    <ul className='list-outside px-4 leading-8'>
                                                                        <li>Type de plan : <span className='font-bold capitalize'>{item.planSouscription.nom}</span> <span>{Math.ceil(item.planSouscription.prix)} € /mois</span></li>
                                                                        <li>Nombre d'utilisateur(s) : <span className='font-bold'>{item.nombreUtilisateurs}</span> </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            {item.urlInstance && <li>
                                                                <h3>Url de l'instance associée : </h3>
                                                                <span className="font-semibold text-gray-800 text-sm">{item.urlInstance}</span>
                                                            </li>}
                                                            {item.supportMaintenance && (
                                                                <li>
                                                                    <div className='flex flex-col w-full leading-8'>
                                                                        <span className='font-medium'><span className='font-normal'>Support de maintenance ({Math.floor(item.supportMaintenance.prix)} €)</span></span>
                                                                        <span className='px-4 '> {item.supportMaintenance.nombreTickets} Tickers/mois </span>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                                {item.implementationServices && (
                                                    <div>
                                                        <span className="text-md lg:text-xl leading-10 font-semibold underline underline-offset-2">Installation</span>
                                                        <div className='px-4'>
                                                            <span className='text-base lg:text-lg font-medium'>Mise en Oeuvre</span>
                                                            <ul className='text-sm lg:text-base px-8 list-disc list-outside'>
                                                                {implementationServicesList}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='w-full md:w-1/2'>
                                                <span className="text-md lg:text-xl leading-10 font-semibold underline underline-offset-2">Forfait</span>
                                                <div className='px-4'>
                                                    <span className='text-base lg:text-lg font-medium'>Hebergement ({item.typeHebergement})</span>
                                                    <ul className='text-sm lg:text-base px-8 list-outside list-disc'>
                                                        <li>{item.nombreItems} {item.produitService.nom.toLowerCase().includes("odoo") ? "employes" : "assets"}</li>
                                                    </ul>
                                                </div>
                                                {item.siteWeb && (
                                                    <div className='px-4'>
                                                        <span className='text-base lg:text-lg font-medium'>Site Web</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='lg:mt-16'>
                                                <span>Coût Total : </span>
                                                <span className='font-semibold'>{Math.floor(item.prixTotal)} €</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <button className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={handleClose}>
                                        Fermer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
                </>
            )}
        </>
    );
}

export default ModalCartItem;