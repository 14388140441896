import React, { useId } from "react";
import { Images } from "../../../constant";
import { H2 } from "../../commons/h_component";


interface ImgUrl {

  url: string | undefined
  path: string
}
export const References = () => {
  const references: ImgUrl[] = [
    {
      url: undefined,
      path: Images.blackForest,
    },
    {
      url: undefined,
      path: Images.eshley,
    }, {
      url: undefined,
      path: Images.blank,
    }, {
      url: undefined,
      path: Images.montreal,
    }, {
      url: undefined,
      path: Images.sea_time,
    }, {
      url: undefined,
      path: Images.simpleStar,
    }, {
      url: "https://luna-wave.ic-cloudapps.com",
      path: Images.luna,
    },






  ];

  // Generate a base ID using useId
  const baseId = useId();

  return (
    <section
      id="section-reference"
      className="w-full h-full flex items-center justify-center mb-24"
    >
      <div className="container w-full h-full px-4 xl:px-16 2xl:px-0 gap-24 flex flex-col items-center justify-center">
        <H2 classNames="" Component={"References"} />
        <div className="w-full h-full flex flex-wrap justify-center gap-8">
          {references.map((value, index) => {
            const id = `${baseId}-${index}`; // Create a unique ID for each item
            return (
              <div
                key={id}
                className="w-[calc(50%-18px)] lg:w-[calc(25%-25px)] xl:w-[calc(22.8%)] 2xl:w-[calc(15%-2px)] bg-gray-100 h-24 flex items-center justify-center"
              >
                {value.url ? <a href={value.url ?? ""}
                  target="_blank"  // Ouvre dans un nouvel onglet
                  rel="noopener noreferrer"  // Sécurise l'ouverture contre les attaques de type 'tabnabbing'

                  className="w-full h-full flex items-center justify-center">
                  <img
                    src={value.path}
                    alt={`references-${value.path}`}
                    className="object-fit h-full"
                  />
                </a> : <img
                  src={value.path}
                  alt={`references-${value.path}`}
                  className="object-fit h-full"
                />}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};