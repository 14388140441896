import { MaintenanceProp } from "../types/model/maintenance_prop";


export const NAME_APP = "CloudApps";

export const SupportPrice: number = 159.99//100000;

export const countries = ["Cameroon"];

export const cameroonCities = ['Douala', 'Yaoundé', 'Bafoussam', 'Garoua', 'Bamenda', 'Kribi', 'Maroua', 'Limbé', 'Ngaoundéré', 'Bertoua'];

export const dailyCost = 119.99 //75000

export const transportAnotherRegion = 23//15000;

export const transportDouala = 7.99//5000

export const initialLocation = "Douala"

export const discountMonth: number = 3

export const monthlySubscription = 12 - discountMonth;

export const websitePrice = 159.99//100000

export const maxAssets = 1000

export const maxUsers = 1000;


export const contactUs = "https://odoo.ic-cloudapps.com/contactus";

export const maintenanceTypes: MaintenanceProp[] = [
    {
        type: 'V1',
        value: "2 tickets maximum par mois",
        price: 79.99 //50000 
    },
    {
        type: 'V2',
        value: "5 tickets maximum par mois",
        price: 159.99//100000 
    },

]
export const whileInViewImage: Object = {
    scale: [0.5, 1],
    opacity: [0, 1],
    transition: {
        duration: 1,
        ease: "easeOut",
    },
};