const authUrl: string = "REACT_APP_AUTH_API/api/v1";
const coreUrl = "REACT_APP_CORE_API/api/v1";
const payUrl = "REACT_APP_PAY_API/api/v1";

// const authUrl = process.env.REACT_APP_AUTH_API + "/api/v1"
// const coreUrl = process.env.REACT_APP_CORE_API + "/api/v1"
// const payUrl = process.env.REACT_APP_PAY_API + "/api/v1"


const googleAuth = authUrl + "/google/login";

export const urls = {
  authUrl,
  googleAuth,
  coreUrl,
  payUrl
};


export default urls;
