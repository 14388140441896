import { faCloud, faNetworkWired, faServer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAdresseContext } from "../../context/AdresseContext";
import { RootState } from "../../store";
import { Panier, PanierItem } from "../../types/model/cart";
import { createSlug } from "../../utils/createSlug";
import ModalCartItem from "./modal_cart_item";

const CartComponent = ({ cart, handleDelete }: { cart: Panier, handleDelete: (itemId: number) => void }) => {
    return (
        <section className="w-full  relative py-8 antialiased  md:py-16">
            <div className=" px-4 2xl:px-0">
                <div className="mt-6 sm:mt-8 md:gap-6 lg:flex lg:items-start xl:gap-8">
                    <DataCart items={cart.items} handleDelete={handleDelete} />
                    <CartSummary cart={cart} />
                </div>
            </div>
        </section>
    );
}

const DataCart = ({ items, handleDelete }: { items: PanierItem[], handleDelete: (itemId: number) => void }) => {

    return (
        <div className="w-full lg:pr-24 2xl:pr-48">
            <div className="space-y-6">
                {items.map((item) => <CartItem key={item.codePanierItem} handleDelete={handleDelete} item={item} />)} {/* Add key */}
            </div>
        </div>
    );
};

const icons = [faCloud, faServer, faNetworkWired];


const CartItem = ({ item, handleDelete }: { item: PanierItem, handleDelete: (itemId: number) => void }) => {
    const icon = useMemo(() => {
        const slug = item.produitService.categorie.slug.toLowerCase();
        if (slug === "paas") return 0;
        if (slug === "saas") return 1;
        return 2;
    }, [item.produitService.categorie.slug]);

    const { deleteItemId } = useSelector((state: RootState) => state.cart);

    const [showModal, setShowModal] = useState(false);

    const handleModalOpen = useCallback((e: any) => {
        e.stopPropagation()
        setShowModal(true);
    }, []);


    const handleEditClick = useCallback((e: any) => {
        e.stopPropagation();
    }, []);

    return (
        <div role="button" onMouseOver={() => { }} onFocus={() => { }} className="hover:shadow-lg rounded-lg border border-gray-200 bg-white p-4 shadow-sm md:p-6">
            <div className="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
                <div className="shrink-0 md:order-1 flex justify-between">
                    <a href={`/products/${createSlug(item.produitService.nom)}`} className='size-20 bg-gray-100 rounded flex items-center justify-center'>
                        <FontAwesomeIcon icon={icons[icon]} className="size-12 text-gray-500" />
                    </a>
                    <div className="lg:hidden flex flex-col justify-between">
                        <div className="flex pl-4 justify-end gap-4">
                            <div className="flex  justify-end gap-8">

                                {!deleteItemId.includes(item.idPanierItem) && (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDelete(item.idPanierItem);
                                        }}
                                        type="button"
                                        className="flex group flex-col gap-1 items-center text-sm font-medium text-red-600"
                                    >
                                        <div className="rounded-full flex items-center justify-center focus-within:outline-red-500">
                                            <svg width={34} height={34} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="fill-red-50 transition-all duration-500 group-hover:fill-red-400" cx={17} cy={17} r={17} />
                                                <path className="stroke-red-500 transition-all duration-500 group-hover:stroke-white" d="M14.1673 13.5997V12.5923C14.1673 11.8968 14.7311 11.333 15.4266 11.333H18.5747C19.2702 11.333 19.834 11.8968 19.834 12.5923V13.5997M19.834 13.5997C19.834 13.5997 14.6534 13.5997 11.334 13.5997C6.90804 13.5998 27.0933 13.5998 22.6673 13.5997C21.5608 13.5997 19.834 13.5997 19.834 13.5997ZM12.4673 13.5997H21.534V18.8886C21.534 20.6695 21.534 21.5599 20.9807 22.1131C20.4275 22.6664 19.5371 22.6664 17.7562 22.6664H16.2451C14.4642 22.6664 13.5738 22.6664 13.0206 22.1131C12.4673 21.5599 12.4673 20.6695 12.4673 18.8886V13.5997Z" stroke="#EF4444" strokeWidth="1.6" strokeLinecap="round" />
                                            </svg>
                                        </div>
                                        <span className='hidden md:block text-xs font-semibold'>
                                            Supprimer
                                        </span>
                                    </button>
                                )}
                                {deleteItemId.includes(item.idPanierItem) && (
                                    <div className="inline-block h-8 w-8 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-red-500 motion-reduce:animate-[spin_1.5s_linear_infinite]">
                                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
                                    </div>
                                )}
                            </div>

                            <a
                                href={`subscribe/${item.produitService.codeProduitService.toLowerCase()}/${item.codePanierItem.toLowerCase()}`}
                                onClick={handleEditClick}
                                className="flex group flex-col gap-1 items-center text-sm font-medium text-primary-500"
                            >
                                <div className="bg-primary-500/15 size-[34px] rounded-full flex items-center justify-center focus-within:outline-primary-500 transition-all duration-500 group-hover:bg-primary-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width={17} className="group-hover:fill-white stroke-2" height={17}>
                                        <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                                    </svg>
                                </div>
                                <span className='opacity-0 md:group-hover:opacity-100 transition-all duration-500 text-xs font-semibold'>
                                    Editer
                                </span>
                            </a>

                        </div>
                        <div className="text-end md:order-4 md:w-32">
                            <p className="text-base font-bold text-gray-900">
                                {item.prixTotal} €
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-between md:order-3 md:justify-end">
                    <div className='flex flex-col items-end justify-end gap-8'>
                        <div className="hidden lg:flex pl-4 justify-end">
                            <div>
                                {!deleteItemId.includes(item.idPanierItem) && (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDelete(item.idPanierItem);
                                        }}
                                        type="button"
                                        className="flex group flex-col gap-1 items-center text-sm font-medium text-red-600"
                                    >
                                        <div className="rounded-full flex items-center justify-center focus-within:outline-red-500">
                                            <svg width={34} height={34} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle className="fill-red-50 transition-all duration-500 group-hover:fill-red-400" cx={17} cy={17} r={17} />
                                                <path className="stroke-red-500 transition-all duration-500 group-hover:stroke-white" d="M14.1673 13.5997V12.5923C14.1673 11.8968 14.7311 11.333 15.4266 11.333H18.5747C19.2702 11.333 19.834 11.8968 19.834 12.5923V13.5997M19.834 13.5997C19.834 13.5997 14.6534 13.5997 11.334 13.5997C6.90804 13.5998 27.0933 13.5998 22.6673 13.5997C21.5608 13.5997 19.834 13.5997 19.834 13.5997ZM12.4673 13.5997H21.534V18.8886C21.534 20.6695 21.534 21.5599 20.9807 22.1131C20.4275 22.6664 19.5371 22.6664 17.7562 22.6664H16.2451C14.4642 22.6664 13.5738 22.6664 13.0206 22.1131C12.4673 21.5599 12.4673 20.6695 12.4673 18.8886V13.5997Z" stroke="#EF4444" strokeWidth="1.6" strokeLinecap="round" />
                                            </svg>
                                        </div>
                                        <span className='opacity-0 md:group-hover:opacity-100 transition-all duration-500 text-xs font-semibold'>
                                            Supprimer
                                        </span>
                                    </button>
                                )}
                                {deleteItemId.includes(item.idPanierItem) && (
                                    <div className="inline-block h-8 w-8 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-red-500 motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
                                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
                                    </div>
                                )}
                            </div>

                            <a
                                href={`subscribe/${item.produitService.codeProduitService.toLowerCase()}/${item.codePanierItem.toLowerCase()}`}
                                onClick={handleEditClick}
                                className="flex group flex-col gap-1 items-center text-sm font-medium text-primary-500"
                            >
                                <div className="bg-primary-500/15 size-[34px] rounded-full flex items-center justify-center focus-within:outline-primary-500 transition-all duration-500 group-hover:bg-primary-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width={17} className="group-hover:fill-white stroke-2" height={17}>
                                        <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                                    </svg>
                                </div>
                                <span className='opacity-0 md:group-hover:opacity-100 transition-all duration-500 text-xs font-semibold'>
                                    Editer
                                </span>
                            </a>
                        </div>

                        <div className="text-end md:order-4 hidden lg:block">
                            <p className="text-base font-bold text-gray-900">
                                {item.prixTotal} €
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full min-w-0 flex-1 md:order-2 md:max-w-md">
                    <div className='flex flex-col'>
                        <a
                            href={`/products/${createSlug(item.produitService.nom)}`}
                            className="text-xl font-semibold text-gray-800 hover:underline"
                        >
                            {item.produitService.nom}
                        </a>
                        <h3 className="text-base font-medium text-gray-900 py-4">
                            {item.planSouscription.nom}
                        </h3>
                        <ul className='list-disc text-sm px-8'>
                            {item.implementationServices && <li>Support de Mise en Oeuvre</li>}
                            {item.supportMaintenance && <li>Service Maintenance (max {item.supportMaintenance.nombreTickets} tickets/mois)</li>}
                            {item.siteWeb && <li>Site Web</li>}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="pt-4 w-full flex items-center justify-end">
                <ModalCartItem
                    item={item}
                    show={showModal}
                    onShow={setShowModal}
                />
            </div>
        </div>
    );
};


interface CartSummaryProps {
    cart: Panier;
}

const CartSummary: React.FC<CartSummaryProps> = ({ cart }) => {
    const { adresses } = useAdresseContext();
    const { currentOrder } = useSelector((state: RootState) => state.order);

    // Memoize the route calculation to avoid recalculating on every render
    const route = useMemo(() => {
        // if (!user) return "/signin";

        if (currentOrder && currentOrder.panier.codePanier === cart.codePanier) {
            return `/shop/checkout`;
        }

        if (adresses.length > 0 && cart) {
            return `/shop/checkout`;
        }

        return "/shop/address";
    }, [currentOrder, cart, adresses]);

    return (
        <div className="mt-6 max-w-sm w-full space-y-6 lg:mt-0">
            <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm sm:p-6">
                <p className="text-xl font-semibold text-gray-900">Récapitulatif de la commande</p>
                <div className="space-y-4">
                    <div className="space-y-2">
                        <dl className="flex items-center justify-between gap-4">
                            <dt className="text-base font-normal text-gray-500">Prix ​​d'origine</dt>
                            <dd className="text-base font-medium text-gray-900">{cart.montantTotal} €</dd>
                        </dl>
                    </div>
                    <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2">
                        <dt className="text-base font-bold text-gray-900">Total</dt>
                        <dd className="text-base font-bold text-gray-900">{cart.montantTotal} €</dd>
                    </dl>
                </div>
                <Link
                    to={route}
                    className={clsx(
                        "flex w-full items-center justify-center rounded-lg bg-primary-500 hover:bg-blue-500 transition-all duration-300 ease-out px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300"
                    )}
                >
                    Paiement
                </Link>

                <div className="flex items-center justify-center gap-2">
                    <span className="text-sm font-normal text-gray-500"> ou </span>
                    <a
                        href="/products"
                        title=""
                        className="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline"
                    >
                        Continuer vos achats
                        <svg
                            className="h-5 w-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 12H5m14 0-4 4m4-4-4-4"
                            />
                        </svg>
                    </a>
                </div>
            </div>

            <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm sm:p-6">
                <form className="space-y-4">
                    <div>
                        <label
                            htmlFor="voucher"
                            className="mb-2 block text-sm font-medium text-gray-900"
                        >
                            Avez-vous un bon ou une carte cadeau?
                        </label>
                        <input
                            type="text"
                            id="voucher"
                            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500"
                            placeholder=""
                        />
                    </div>
                    <button
                        type="submit"
                        className="flex w-full items-center justify-center rounded-lg bg-primary-500 hover:bg-blue-500 transition-all duration-300 ease-out px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300"
                    >
                        Appliquer le code
                    </button>
                </form>
            </div>
        </div>
    );
};


export default CartComponent;

