import background from "../assets/background1.jpg";
import edit from "../assets/edit.svg";
import build from "../assets/build.svg";
import all03 from "../assets/all03.svg";
import logo from "../assets/cloudapps-logo-v3.png";
import odoo from "../assets/odoo.png";
import glpi from "../assets/glpi.png";
import bigbluebutton from "../assets/bigbluebutton.jpg";
import saas from "../assets/saas.png";
import paas from "../assets/paas.png";
import consulting1 from "../assets/consulting.png";
import bg1 from "../assets/bg1.jpeg";
import bg2 from "../assets/bg2.png";
import bg3 from "../assets/bg3.png";
import bg4 from "../assets/bg4.png";
import bg5 from "../assets/bg4.jpeg";
import logoFooter from "../assets/logo.png";
import cloudConsulting from "../assets/cloudConsulting.jpeg";
import arrow from "../assets/utils/arrow.svg";
import fraser from "../assets/employes/frazer.jpeg";
import raquiel from "../assets/employes/raquiel.jpeg";
import hippo from "../assets/employes/hippo.jpeg";

//Partners
import blackForest from "../assets/partners/blackforest.png";
import eshley from "../assets/partners/eshley.png";
import montreal from "../assets/partners/montreal.png";
import sea_time from "../assets/partners/sea_time.png";
import simpleStar from "../assets/partners/simple_star.png";
import luna from "../assets/partners/luna.webp";
import blank from "../assets/partners/blank.png";

import mtn from "../assets/mtn.png";
import orange from "../assets/orange.png";
import visa from "../assets/VISA.png";
import masterCard from "../assets/MasterCard.png";
import americanExpress from "../assets/AmericanExpress.png";
import maestro from "../assets/Maestro.png";

import corner_1 from "../assets/utils/corner_1.svg";
import green_highlight_03 from "../assets/utils/green_highlight_03.svg";
import secondary_arrow_sm_03 from "../assets/utils/secondary_arrow_sm_03.svg";
import yellow_highlight_bold_04 from "../assets/utils/yellow_highlight_bold_04.svg";
import google from "../assets/google.svg";
import login from "../assets/secure-login-animate.svg";
import error404 from "../assets/utils/404.svg";
import emptyCart from "../assets/utils/empty-cart-illustration.png";


import about from "../assets/about.png";
import unavailable1 from "../assets/rb_2535.png";
import unavailable2 from "../assets/rb_3689.png";
import ecriture from "../assets/ecriture1.png";


const imagesApp = {
    background,
    bg5,
    cloudConsulting,
    logoFooter,
    bg1,
    bg2,
    bg3,
    bg4,
    arrow,
    edit,
    fraser,
    raquiel,
    hippo,
    build,
    all03,
    logo,
    blank,
    blackForest,
    simpleStar,
    sea_time,
    eshley,
    montreal,
    luna,
    glpi,
    bigbluebutton,
    odoo,
    consulting1,
    paas,
    google,
    saas,
    mtn,
    orange,
    visa,
    maestro,
    masterCard,
    americanExpress,
    corner_1,
    yellow_highlight_bold_04,
    secondary_arrow_sm_03,
    green_highlight_03,
    login,
    error404,
    emptyCart,
    about, unavailable1, unavailable2, ecriture
};

export default imagesApp;
