import { clsx } from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { z } from 'zod';
import { Images } from '../constant';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { login } from '../store/authSlice';
import { GoogleButton } from '../components/auth/google_button';

// 2. Créer un schéma de validation pour les données du formulaire
const formSchema = z.object({
    email: z.string().email({ message: "Email invalide" }), // Vérifie que c'est un email valide
    password: z.string().min(1, { message: "Le mot de passe ne peut pas être vide" }), // Non nul
});

// 3. Définir un type TypeScript basé sur le schéma
type FormData = z.infer<typeof formSchema>;


const SignIn = () => {

    return (
        <div className="w-full  py-24">
            <div className="flex max-w-lg mx-auto  my-16 overflow-hidden bg-white rounded-lg lg:space-x-8 bg lg:max-w-5xl">
                <div className="items-center hidden lg:flex lg:w-1/2">
                    <img
                        src={Images.login}
                        alt="secure-login-animate.svg"
                    />
                </div>{" "}
                <div className="w-full px-6 py-8 md:px-8 lg:w-1/2">
                    <h2 className="text-2xl font-semibold text-center text-gray-700 ">
                        Bienvenue
                    </h2>{" "}
                    <p className="mt-2 text-xl text-center text-gray-600 ">
                        Nous sommes <span className="text-primary">Heureux</span> de vous revoir
                    </p>{" "}
                    <div className="mt-4 space-y-3 sm:flex sm:items-center sm:space-x-4 sm:space-y-0">
                        <GoogleButton />
                    </div>{" "}
                    <div className="flex items-center justify-between mt-4">
                        <span className="w-1/5 border-b  lg:w-1/4" />{" "}
                        <a
                            href="/"
                            className="text-xs text-center text-gray-500 uppercase  hover:underline"
                        >
                            ou utilisez email
                        </a>{" "}
                        <span className="w-1/5 border-b  lg:w-1/4" />
                    </div>{" "}
                    <FormLogin></FormLogin>

                    <div className="flex items-center justify-between mt-4">
                        <span className="w-1/5 border-b md:w-1/4" />{" "}
                        <a
                            href="/register"
                            className="text-xs text-gray-500 uppercase  hover:underline"
                        >
                            Créer un compte
                        </a>{" "}
                        <span className="w-1/5 border-b md:w-1/4" />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default SignIn;



const FormLogin = () => {
    const [visible, setVisible] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState<FormData>({
        email: '',
        password: '',
    });


    // Créer un état pour les erreurs de validation
    const [formErrors, setFormErrors] = useState<{ email?: string; password?: string }>({});
    const dispatch = useDispatch<AppDispatch>();
    const { error, loading, user } = useSelector((state: RootState) => state.auth);

    // Fonction de gestion du changement des inputs
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Valider les données du formulaire
        const result = formSchema.safeParse(formData);

        if (!result.success) {
            // En cas d'erreurs de validation, extraire les messages et les afficher
            const errors = result.error.flatten().fieldErrors;
            setFormErrors({
                email: errors.email?.[0],
                password: errors.password?.[0],
            });
        } else {
            // Si tout est bon, vous pouvez envoyer les données
            dispatch(login(formData.email, formData.password))

            setFormErrors({});
        }
    };

    const showToast = useCallback(
        () => {
            if (!loading) {
                if (error) {
                    toast.error(error || 'Erreur inattendue');
                } else if (user) {
                    const from = location.state?.from || localStorage.getItem('next-route') || '/';
                    localStorage.removeItem('next-route'); // Nettoyage après récupération
                    toast.success("Vous êtes connecté !!");
                    navigate(from);
                }

            }
        },
        [error, location, loading, user, navigate],
    )

    useEffect(() => {
        showToast()
    }, [dispatch, error, loading, user, showToast])

    return (
        <form
            method="POST"
            onSubmit={handleSubmit}
        >

            <div className="mt-4">
                <label htmlFor='email' className="block mb-2 text-sm text-gray-600 ">
                    E-mail
                </label>{" "}
                <input
                    id='email'
                    type="email"
                    name="email"
                    defaultValue=""
                    required
                    value={formData.email}
                    onChange={handleChange}
                    autoComplete="email"
                    className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-lg  focus:border-primary  focus:outline-none focus:ring focus:ring-primary  focus:ring-opacity-20"
                />

                {formErrors.email && <span className='block my-1 text-sm text-gray-600 ' style={{ color: 'red' }}>{formErrors.email}</span>}
            </div>{" "}
            <div className="relative mt-4">
                <label htmlFor='pass' className="block mb-2 text-sm text-gray-600">
                    Mot de passe
                </label>
                <div className="relative">
                    <input
                        id='pass'
                        type={visible ? "text" : "password"}
                        name="password"
                        required

                        value={formData.password}
                        onChange={handleChange}
                        className="w-full pl-4 pr-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-lg  focus:border-primary  focus:outline-none focus:ring focus:ring-primary  focus:ring-opacity-20"
                    />
                    <button onClick={e => setVisible(prev => !prev)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"

                            className={clsx("absolute right-2 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-400", {
                                "hidden": !visible
                            })}>
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={clsx("absolute right-2 top-1/2 transform -translate-y-1/2 h-6 w-6 text-gray-400", {
                            "hidden": visible
                        })}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                        </svg>
                    </button>



                </div>
                {formErrors.password && (
                    <span className="block my-1 text-sm text-gray-600" style={{ color: 'red' }}>
                        {formErrors.password}
                    </span>
                )}
            </div>
            <div className="flex justify-between mt-4">
                <div className="col-md-6 offset-md-4">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            name="remember"
                            id="remember"
                            className="border-gray-200 rounded shadow-sm text-primary focus:border-primary  focus:outline-none focus:ring focus:ring-primary  focus:ring-opacity-20"
                        />{" "}
                        <label
                            htmlFor="remember"
                            className="ml-2 text-gray-700"
                        >
                            Se souvenir de moi
                        </label>
                    </div>
                </div>{" "}
                {/* <a
                    // href="https://www.creative-tim.com/twcomponents/password/reset"
                    className="text-sm text-gray-600  hover:underline"
                >
                    Mot de passe oublié?
                </a> */}
            </div>{" "}
            <div className="mt-8">
                <button
                    type="submit"
                    className={clsx("w-full px-4 py-2 tracking-wide text-white transition-colors duration-300 transform rounded-md bg-primary-500 hover:bg-primary-500/70 focus:outline-none focus:bg-primary/70"
                        , { "hidden": loading })}>

                    Se connecter
                </button>


                <button
                    type="button"
                    disabled={true}
                    className={clsx("w-full py-2 px-4 flex justify-center items-center  bg-primary-500/70   text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg",
                        { "hidden": !loading }
                    )}
                >
                    <svg
                        width={20}
                        height={20}
                        fill="currentColor"
                        className="mr-2 animate-spin"
                        viewBox="0 0 1792 1792"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                    </svg>
                    loading
                </button>
            </div>
        </form>
    )
}
