import { Adresse } from "../types/model/adresseModel";
import { Order } from "../types/model/order";
import coreApi from "./path/core_api";


const orderService = {

    createOrder: async (codePanier: string, codeAdresse: string): Promise<Order> => {
        try {
            const response = await coreApi.post<Order>(`/commandes/create-order/?codePanier=${codePanier}`, {
                'codeAdresse': codeAdresse
            })
            return response.data

        } catch (error) {
            console.error('create-oder:', error)
            throw error;
        }
    },

    updateOrder: async (codeCommande: string, codeAdresse: string): Promise<Order> => {
        try {
            const response = await coreApi.put<Order>(`/commandes/update-order/?codeCommande=${codeCommande}`, {
                'codeAdresse': codeAdresse
            })
            return response.data

        } catch (error) {
            console.error('create-oder:', error)
            throw error;
        }
    },


    cancelOrder: async (codeUtilisateur: string, itemCode: string): Promise<void> => {
        try {
            await coreApi.put<Order>(`/commandes/cancel_order/?codeCommande=${itemCode}`,)


        } catch (error) {
            console.error('cancel-order:', error)
            throw error;
        }
    },
    getOrder: async (codeUtilisateur: string): Promise<Order> => {
        try {
            const response = await coreApi.get<Order[]>(`/commandes/search?codeUtilisateur=${codeUtilisateur}&statut=PEN`)
            return response.data[0]

        } catch (error) {
            console.error('GET-oder:', error)
            throw error;
        }
    },

    getOrders: async (codeUtilisateur: string): Promise<Order[]> => {
        try {
            const response = await coreApi.get<Order[]>(`/commandes/search?codeUtilisateur=${codeUtilisateur}`)
            const orders: Order[] = response.data;

            return orders;

        } catch (error) {
            console.error('GET-oder:', error)
            throw error;
        }
    },

    getAdresse: async (codeUtilisateur: string): Promise<Adresse[]> => {
        try {
            const response = await coreApi.get<Adresse[]>(`/adresses/search?codeUtilisateur=${codeUtilisateur}`);
            return response.data;
        } catch (error) {
            console.log(error)
            throw error
        }
    }
}

export default orderService;

