import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAdresseContext } from "../../context/AdresseContext";
import { useFormContext } from "../../context/formContext";
import { InternalError } from "../InternalError";
import { RootState } from "../../store";
import { Adresse } from "../../types/model/adresseModel";
import { initialFormData } from "../../types/model/form_data_model";
import LoadingSpinner3 from "../../components/loading/loading_spinner3";
import ModalDialog from "../../components/modal/modal_t";
import FormSubscription from "../../components/subscription/form_subscription";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAdresses from "../../hooks/useAdresses";


export const AddressPage = () => {
    const { loading, error, createAdresse, updateAdresse } = useAdresseContext();
    const { getAdresse, adresse, getAdresseloading, errorGetAdresse } = useAdresses();
    const { code } = useParams<{ code?: string }>();
    const { user } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    const [hasAttemptedToCreateAddress, setHasAttemptedToCreateAddress] = useState(false);
    const { formData, setFormData } = useFormContext();

    // Memoize the address creation and update logic
    const handleCreateAdresse = useCallback(async () => {
        if (user) {
            const address: Adresse = {
                codeAdresse: "d",
                nom: formData.firstName,
                prenom: formData.lastName,
                telephone: formData.phoneNumber,
                adresse: formData.address,
                email: formData.email,
                entreprise: formData.company,
                codePostal: formData.postalCode,
                pays: formData.country,
                ville: formData.city,
                parDefaut: true,
                tva: parseFloat(formData.taxNumber) ?? 0,
            };
            setHasAttemptedToCreateAddress(true);
            createAdresse(address);
        }
    }, [user, formData, createAdresse]);

    const handleUpdateAdresse = useCallback(async () => {
        if (user && adresse) {
            const updatedAddress: Adresse = {
                codeAdresse: adresse.codeAdresse,
                nom: formData.firstName,
                prenom: formData.lastName,
                telephone: formData.phoneNumber,
                adresse: formData.address,
                email: formData.email,
                entreprise: formData.company,
                codePostal: formData.postalCode,
                pays: formData.country,
                ville: formData.city,
                parDefaut: true,
                tva: parseFloat(formData.taxNumber) ?? 0,
            };
            setHasAttemptedToCreateAddress(true);
            updateAdresse(updatedAddress);
        }
    }, [user, adresse, formData, updateAdresse]);

    // Fetch address only when code changes and not infinitely
    useEffect(() => {
        if (code) {
            getAdresse(code);
        }
    }, [code, getAdresse]);

    // Handle navigation to next and previous steps
    const handleNextStep = useCallback(() => {
        if (code) {
            handleUpdateAdresse();
        } else {
            handleCreateAdresse();
        }
    }, [code, handleCreateAdresse, handleUpdateAdresse]);

    const handlePreviousStep = useCallback(() => {
        navigate(`/cart`);
    }, [navigate]);



    const handleResetForm = useCallback(() => {
        setFormData(initialFormData);
    }, [setFormData]);

    // Toast notification
    const showToast = useCallback(() => {
        if (!loading && error) {
            toast.error(error || "Erreur inattendue");
            setHasAttemptedToCreateAddress(false);
        }
    }, [loading, error]);

    // Navigate after address creation or update
    useEffect(() => {
        if (hasAttemptedToCreateAddress && !loading && !error) {
            navigate(`/shop/checkout`);
        }
    }, [hasAttemptedToCreateAddress, loading, error, navigate]);

    // Set form data when an address is available
    useEffect(() => {
        if (adresse) {
            setFormData({
                firstName: adresse.nom ?? "",
                lastName: adresse.prenom ?? "",
                company: adresse.entreprise,
                email: adresse.email ?? "",
                address: adresse.adresse,
                postalCode: adresse.codePostal,
                city: adresse.ville,
                country: adresse.pays,
                taxNumber: "",
                phoneNumber: adresse.telephone ?? "",
                methodOfPayment: "",
            });
        } else if (code == null) {
            setFormData({
                firstName: user?.prenom ?? "",
                lastName: user?.nom ?? "",
                company: "",
                email: user?.email ?? "",
                address: "",//"Littoral-cameroun",
                postalCode: "", //"237",
                city: "",//"Douala",
                country: "",//"Cameroun",
                taxNumber: "",
                phoneNumber: user?.telephone ?? "",
                methodOfPayment: "",
            });
        }
    }, [adresse, setFormData, code, user]);

    useEffect(() => {
        showToast();
    }, [loading, error, showToast]);

    if (getAdresseloading) {
        return (
            <div className="w-full h-screen flex items-center">
                <div className="mx-auto container px-4 xl:px-16 2xl:px-0">
                    <LoadingSpinner3 />
                </div>
            </div>
        );
    }

    if (errorGetAdresse) {
        return (
            <section className="w-full h-screen flex items-center">
                <div className="mx-auto container px-4 xl:px-16 2xl:px-0">
                    <InternalError />
                </div>
            </section>
        );
    }

    return (
        <div className={clsx("flex flex-col items-center relative lg:h-full pt-14 pb-24")}>
            <div className="w-full container mx-auto px-4 xl:px-16 2xl:px-0">
                <div className="w-full h-full py-14 pb-24">
                    <div className="w-full h-full mx-auto flex flex-col gap-8">
                        <h2 className="text-4xl font-semibold mb-4">Adresse de facturation</h2>
                        <div className="w-full flex flex-col lg:flex-row">
                            <div className="w-full lg:px-0 xl:w-3/5 flex items-center justify-center">
                                <div className="relative bg-white shadow-lg w-full h-full">
                                    <div className={clsx("relative w-full mx-auto")}>
                                        <div className="w-full container mx-auto p-4 flex flex-col items-center justify-center gap-10 py-8">
                                            <FormSubscription
                                                onPrevious={handlePreviousStep}

                                                onReset={handleResetForm}
                                                onNext={handleNextStep}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDialog isOpen={loading && hasAttemptedToCreateAddress}>
                <div className="mx-auto container px-4 xl:px-16 2xl:px-0">
                    <LoadingSpinner3 />
                </div>
            </ModalDialog>
        </div>
    );
};
